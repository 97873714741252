import React from 'react'

const Terms = () => {
    // TODO - display PDF file "terms" from public folder
  return (
    <div>
        <embed src={"/terms.pdf"} style={{width: '100%', height: '100vh'}}/>
    
    </div>
  )
}

export default Terms