import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { getUserData } from "../../../assets/DataUtils";

const VideoPageBuy = ({ state, setState }) => {
  const { video_id } = useParams();
  const [bought, setBought] = useState(false);
  useEffect(() => {
    if (document.getElementById("iframe")) {
      if (!bought) {
        checkIframe();
      }
    }
    // Check for URL changes periodically (e.g., every second)
  }, []);

  const checkIframe = async () => {
    const intervalId = setInterval(function () {
      const currentURL = document.getElementById("iframe")?.contentWindow.location.href;
      if (currentURL?.includes("thank_you")) {
        setBought(true);
        clearInterval(intervalId); // Clear the interval
      }
    }, 1000);
  };
  const handleBuy = async () => {
    setTimeout(() => {
      window.location = "/vod";
    }, 1000 * 5);
  };
  useEffect(() => {
    if(bought) {
      handleBuy();
    }
  }, [bought]);

  return (
    <div className="payment-page">
      <iframe
        id="iframe"
        src="https://secure.cardcom.solutions/e/9JTE7DVcYEWBMCm4sNrbsg"
        // src="http://localhost:3001/thank_you/vod"
        width="100%"
        title="Cardcom Payment Form"
      ></iframe>
    </div>
  );
};

export default VideoPageBuy;
