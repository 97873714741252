import React, { useEffect } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import "./ThankYou.css";
import { getUserTransactions } from "../../assets/DataUtils";
const ThankYou = ({ state, setState, navigate }) => {
  const { backTo } = useParams();
  const type = window.location.pathname.includes("online")
    ? "/online"
    : window.location.pathname.includes("vod")
    ? "/vod"
    : false;
  return (
    <div className="thank-you">
      <div className="card">
        <div className="title">תודה!</div>
        <div>הרכישה הסתיימה בהצלחה</div>
        {type ? (
          <div>
            ניתן לצפות ב{type == "/online" ? "הדרכה" : "הקלטה"} באמצעות דף ה
            {type == "/online" ? "הדרכות אונליין" : "הדרכות מוקלטות"}
          </div>
        ) : (
          <div>ניתן לצפות במוצר הנרכש דרך דף הרכישה</div>
        )}
        <Link to={`/account/buys`}>
          <div className="btn btn-success mt-3">מעבר לחשבון</div>
        </Link>
      </div>
    </div>
  );
};

export default ThankYou;
