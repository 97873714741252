import React, { useEffect, useState } from "react";
import vodCoverImg from "../../../assets/images/vod_cover.png";
import { videos } from "../../../assets/data/videos";
import { Button, Card, Container, Row } from "react-bootstrap";
import Icon from "@mdi/react";
import {
  mdiCalendarMonth,
  mdiClockOutline,
  mdiEye,
  mdiTimerSand,
} from "@mdi/js";
import moment from "moment";
import { Link, useNavigate } from "react-router-dom";
import "./Vod.css";
import { findItem, hasItem } from "../../../assets/Utils";
import { getVideos } from "../../../assets/DataUtils";
const Vod = ({ state, setState }) => {
  const navigate = useNavigate();
  const [videos, setVideos] = useState(state.videos || []);

  useEffect(() => {
    setVideos(state.videos);
  }, [state.videos?.length]);

  const goBuy = async (video) => {
    // await setState({ ...state, videos: videos });
    navigate(`/vod/${video.id}/buy`);
  };

  const entervideo = async (video) => {
    // await setState({ ...state, videos: videos });
    navigate(`/vod/${video.id}`);
  };
  return (
    <div className="vod-page">
      <div className="cover">
        <div className="cover-image">
          <img src={vodCoverImg} />
          <div className="cover-fade" />
        </div>
      </div>
      <div className="vod-page-body m-2">
        <h3 className="text-center my-3">הדרכות מוקלטות</h3>
        {videos.filter(
          (video) =>
            video.published_datetime < moment().format("YYYY-MM-DD") &&
            video.active
        ).length < 1 && (
          <>
            <div className="fs-3 text-center">הקלטות חדשות יעלו בקרוב</div>
          </>
        )}
        <Container className="videos-list mt-3">
          {videos
            ?.sort((a, b) => b.date - a.date)
            .map((video) =>
              state.user?.admin ||
              (video.published_datetime < moment().format("YYYY-MM-DD") &&
                video.active) ? (
                <Row
                  className="mx-2 mt-2 d-flex flex-column"
                  key={`video-${video.id}`}
                >
                  <Card className="video-card shadow">
                    <Card.Body className="video-body">
                      <div className="video-info">
                        <Card.Text className="title h5">
                          {video.title}
                        </Card.Text>
                        <Card.Text className="video-intro">
                          <div
                            dangerouslySetInnerHTML={{ __html: video?.intro }}
                          />
                        </Card.Text>
                        <div className="video-schedule">
                          <span>
                            <Icon path={mdiTimerSand} size={1} />{" "}
                            {video.duration} דקות
                          </span>
                          <span>
                            <Icon path={mdiEye} size={1} />{" "}
                            {hasItem(state.user?.transactions, video.id)
                              ? `גישה עד ${moment(
                                  findItem(state.user?.transactions, video.id)
                                    .DealDate
                                )
                                  .add(14, "days")
                                  .format("DD.MM.YYYY")}`
                              : "גישה למשך 14 יום"}
                          </span>
                          {state.user?.admin &&
                            video.published_datetime >
                              moment().format("YYYY-MM-DD") && (
                              <div>תאריך עתידי</div>
                            )}
                          {state.user?.admin && !video.active && (
                            <div className="text-danger fw-bold">לא פעיל</div>
                          )}
                          {/* <span>
                            פורסם בתאריך:{" "}
                            {moment(video.published_datetime).format("DD.MM.YYYY")}
                          </span> */}
                        </div>
                        {/* <Card.Text className='fw-bold d-flex flex-row-reverse ms-auto'> */}
                        {/* </Card.Text> */}
                        <Link to={`/vod/${video.id}/info`}>
                          <Button className="shadow mt-2" variant="primary">
                            פרטים
                          </Button>
                        </Link>
                      </div>
                      <div className="video-promo">
                        {video.image_url && (
                          <img
                            src={video.image_url}
                            className="shadow-lg video-image"
                            width={"100px"}
                          />
                        )}
                        <div
                          onClick={() =>
                            hasItem(state.user?.transactions, video.id)
                              ? entervideo(video)
                              : goBuy(video)
                          }
                          className="d-flex flex-column justify-content-center align-items-center mt-3"
                        >
                          <div className="price-display fw-bold">
                            {!hasItem(state.user?.transactions, video.id) ? (
                              <span>{video.price.toFixed(2)} ש"ח</span>
                            ) : (
                              <span>נרכש</span>
                            )}
                          </div>
                          <div className="action-btn shadow pointer">
                            <Button variant={"success"}>
                              {hasItem(state.user?.transactions, video.id)
                                ? "צפה"
                                : "קנה"}
                            </Button>
                          </div>
                        </div>
                      </div>
                    </Card.Body>
                  </Card>
                </Row>
              ) : null
            )}
        </Container>
      </div>
    </div>
  );
};

export default Vod;
