export const posts = [{
    id: 0,
    title: 'כותרת',
    subtitle: 'תת כותרת',
    published_date: '09.09.2023',
    published: false,
    locked: false,
    free: true,
    image_url: '',
    html: ``
}]