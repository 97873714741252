import moment from 'moment';

export const videos = [
  {
    id: 'vod_ai',
    title: 'בינה מלאכותית',
    intro: `lorem ipsum lorem ipsum lorem ipsum lorem ipsum lorem ipsum lorem ipsum lorem ipsum lorem ipsum lorem ipsum lorem ipsum lorem ipsum lorem ipsum lorem ipsum lorem ipsum lorem ipsum lorem ipsum lorem ipsum lorem ipsum lorem ipsum lorem ipsum lorem ipsum lorem ipsum`,
    html: `<div>
        <p>
          בינה מלאכותית יכולה לעשות מהפכה בעולם החינוך, אך מורים רבים עדיין לא מכירים
          את הפוטנציאל הגלום בה. הגיע הזמן לגלות כיצד בינה מלאכותית יכולה לשנות את עולם
          החינוך.
        </p>
        <p>
          בהדרכה המוקלטת הזו תחשפו לעולם המרתק של "בינה מלאכותית" בחינוך. תוכלו לצפות
          בה בנוחות מהבית בזמן שנוח לכם ולחזור אליה כרצונכם במשך 14 יום.
        </p>
        <p>
          בנוסף, נבין מהי בינה מלאכותית ומהם העקרונות שעומדים מאחוריה. נכיר דרכים
          יצירתיות שבהן היא יכולה לשפר את חווית הלמידה וההוראה. נלמד כיצד ניתן
          להשתמש "בבינה מלאכותית" על מנת לספק לתלמידים סיוע מותאם אישית. נגלה
          כיצד "בינה מלאכותית" מאפשרת לנו לבנות חומרי לימוד דינמיים המותאמים לצרכיו
          הייחודיים של כל לומד.
        </p>
        <p>
          נבין גם כיצד ניתן להשתמש בבינה מלאכותית על מנת להנגיש חינוך לתלמידים עם
          מוגבלויות. ולבסוף נתנסה בפועל במגוון כלים חינמיים מבוססי בינה מלאכותית
          בהם תוכלו לעשות שימוש מידי בכיתה. תצאו מההדרכה עם ערכת כלים מעשית ליישום
          בינה מלאכותית בעבודתכם.
        </p>
        <p class="fw-bold">
          אז אם מעניין אותך להבין טוב יותר כיצד בינה מלאכותית משנה את פני החינוך – זו
          ההזדמנות שלך!
        </p>
      </div>`,
    published_datetime: '',
    price: 45,
    duration: 90,
    image_url: 'aiCover',
    video_url: 'http://commondatastorage.googleapis.com/gtv-videos-bucket/sample/BigBuckBunny.mp4',
    active: false,
  },
  {
    id: 'vod_ai_chat',
    title: 'בינה מלאכותית - chatGPT',
    intro: `lorem ipsum lorem ipsum lorem ipsum lorem ipsum lorem ipsum lorem ipsum lorem ipsum lorem ipsum lorem ipsum lorem ipsum lorem ipsum lorem ipsum lorem ipsum lorem ipsum lorem ipsum lorem ipsum lorem ipsum lorem ipsum lorem ipsum lorem ipsum lorem ipsum lorem ipsum`,
    html: `<div>
    <p>
      צ'אטGPT הוא אחד הכלים המתקדמים והמרשימים ביותר שפותחו בתחום הבינה המלאכותית.
    </p>
    <p>
      בהדרכה המוקלטת הזו תוכלו להיחשף בנוחות מהבית ובזמן שנוח לכם ליכולות המדהימות של הצ'אט. תלמדו ותכירו סוגים שונים של צ'אטים ותראו כיצד תוכלו לשלב כלי זה בעבודת ההוראה היומיומית שלכם.
    </p>
    <p>
      תכירו את הטכנולוגיה שמאחורי צ'אט GPT ותלמדו כיצד ליישם את היכולות שלו כעזר וירטואלי בכיתה.
    </p>
    <p>
      תלמדו ותחשפו ליכולת המרשימה שלו בהבנת שפה וייצרת טקסט באופן עצמאי.
    </p>
    <p>
      בהדרכה נתמקד ביכולת של הצ'אט לסייע לעובדי הוראה ביצרת: מערכי שיעור, משחקים, יחידות הוראה, לוחות קיר, ממני אלייך, שאלות, הערכות ועוד.
    </p>
    <p>
      בסוף ההדרכה תצאו מצוידים בידע מקיף ומעמיק שיכיל כל מה שאתם צריכים על מנת להפוך את ההוראה שלכם לאיכותית, מעניינת ומותאמת יותר עבור התלמידים שלכם באמצעות שימוש יעיל ומושכל בצ'אט.
    </p>
    <p>
      רכשו עכשיו והוסיפו כלי בינה מלאכותית מתקדם לארגז הכלים הפדגוגי שלכם.
    </p>
  </div>`,
    published_datetime: '',
    price: 45,
    duration: 90,
    image_url: 'aiCover',
    video_url: 'http://commondatastorage.googleapis.com/gtv-videos-bucket/sample/BigBuckBunny.mp4',
    active: false,
  },
  {
    id: 'vod_canva_1',
    title: 'קנבה (CANVA) למתחילים',
    intro: `lorem ipsum lorem ipsum lorem ipsum lorem ipsum lorem ipsum lorem ipsum lorem ipsum lorem ipsum lorem ipsum lorem ipsum lorem ipsum lorem ipsum lorem ipsum lorem ipsum lorem ipsum lorem ipsum lorem ipsum lorem ipsum lorem ipsum lorem ipsum lorem ipsum lorem ipsum`,
    html: `<div>
    <p>
      האם אתם מתקשים ליצור חומרי למידה וויזואליים מרשימים ללא ידע בעיצוב גרפי?
    </p>
    <p>
      הגיע הזמן להכיר את קנבה!
    </p>
    <p>
      קנבה הוא אתר אינטרנט המאפשר יצירת עיצובים מקצועיים בקלות ללא צורך בידע מוקדם. ולכם המורים מגיעה גרסת הפרו ב-ח-י-נ-ם!
    </p>
    <p>
      בהדרכה המוקלטת הזו תוכלו ללמוד בנוחות מהבית בזמן שנוח לכם כיצד ליצור חומרי למידה וויזואליים כגון: מצגות, דפי עבודה, פוסטרים ועוד.
    </p>
    <p>
      נכיר את ממשק האתר, נבחן טמפלטים ועיצובים מוכנים ונלמד טיפים וטריקים לעבודה מהירה ויעילה בקנבה.
    </p>
    <p>
      בנוסף אתן בהדרכה חשיפה לקנבה (AI) ואראה איך קנבה עושה שימוש בבינה מלאכותית שמסייעת ליצור עיצובים מגוונים באופן מהיר, חכם ומותאם אישית.
    </p>
    <p>
      בהדרכה אצייד אתכם בכלים מעשיים ליצירה של חומרי למידה מרהיבים בקלילות ובמהירות בהם תוכלו לעשות שימוש כבר מחר בכיתה!
    </p>
    <p>
      וכל זה ללא ידע קודם בתחום הגרפיקה.
    </p>
    <p>
      ההדרכה תהיה זמינה לכם באתר למשך 14 יום.
    </p>
    <p>
      רכשו היום את ההקלטה של הדרכת קנבה למתחילים ותהפכו את השיעורים שלכם לוויזואליים ומעניינים יותר.
    </p>
  </div>`,
    published_datetime: '',
    price: 45,
    duration: 90,
    image_url: 'aiCover',
    video_url: 'http://commondatastorage.googleapis.com/gtv-videos-bucket/sample/BigBuckBunny.mp4',
    active: false,
  },
  {
    id: 'vod_canva_2',
    title: 'קנבה (CANVA) למתקדמים',
    intro: `lorem ipsum lorem ipsum lorem ipsum lorem ipsum lorem ipsum lorem ipsum lorem ipsum lorem ipsum lorem ipsum lorem ipsum lorem ipsum lorem ipsum lorem ipsum lorem ipsum lorem ipsum lorem ipsum lorem ipsum lorem ipsum lorem ipsum lorem ipsum lorem ipsum lorem ipsum`,
    html: `<div>
        <div>
          <p>האם אתם מתקשים ליצור חומרי למידה וויזואליים מרשימים ללא ידע בעיצוב גרפי?</p>
          <p>הגיע הזמן להכיר את קנבה!</p>
        </div>
        <div>
          <p>קנבה הוא אתר אינטרנט המאפשר יצירת עיצובים מקצועיים בקלות ללא צורך בידע מוקדם. ולכם המורים מגיעה גרסת הפרו ב-ח-י-נ-ם!</p>
          <p>בהדרכה המוקלטת הזו תוכלו ללמוד בנוחות מהבית בזמן שנוח לכם כיצד ליצור חומרי למידה וויזואליים כגון: מצגות, דפי עבודה, פוסטרים ועוד.</p>
        </div>
        <div>
          <p>נכיר את ממשק האתר, נבחן טמפלטים ועיצובים מוכנים ונלמד טיפים וטריקים לעבודה מהירה ויעילה בקנבה.</p>
          <p>בנוסף אתן בהדרכה חשיפה לקנבה (AI) ואראה איך קנבה עושה שימוש בבינה מלאכותית שמסייעת ליצור עיצובים מגוונים באופן מהיר, חכם ומותאם אישית.</p>
        </div>
        <div>
          <p>בהדרכה אצייד אתכם בכלים מעשיים ליצירה של חומרי למידה מרהיבים בקלילות ובמהירות בהם תוכלו לעשות שימוש כבר מחר בכיתה!</p>
          <p>וכל זה ללא ידע קודם בתחום הגרפיקה.</p>
        </div>
        <div>
        <p class="fw-bold">רכשו היום את ההקלטה של הדרכת קנבה למתחילים ותהפכו את השיעורים שלכם לוויזואליים ומעניינים יותר.</p>
        <p>ההדרכה תהיה זמינה לכם באתר למשך 14 יום.</p>
        </div>
      </div>`,
    published_datetime: '',
    price: 45,
    duration: 90,
    image_url: 'aiCover',
    video_url: 'http://commondatastorage.googleapis.com/gtv-videos-bucket/sample/BigBuckBunny.mp4',
    active: false,
  },
];
