import moment from "moment";
import React, { useEffect, useState } from "react";
import { Container, Row, Col, Card, Button } from "react-bootstrap";
import Icon from "@mdi/react";
import { mdiCalendarMonth, mdiClockOutline, mdiTimerSand } from "@mdi/js";
import "./OnlinePage.css";
import { Link, useNavigate } from "react-router-dom";

import AboutCoverImg from "../../../assets/images/AI_tutorials_cover.png";
import { hasEvent } from "../../../assets/Utils";
import { collection, deleteDoc, doc } from "firebase/firestore";
import { firestoreDB } from "../../../firestore";
const OnlinePage = ({ state, setState }) => {
  const navigate = useNavigate();
  const [events, setEvents] = useState(state.events || []);

  // useEffect(() => {
  //   setEventsFromDB();
  // }, []);

  const goBuy = async (event) => {
    await setState({ ...state, events });
    navigate(`/online/${event.id}/register`);
  };

  const enterEvent = async (event) => {
    await setState({ ...state, events });
    navigate(`/online/${event.id}/enter`);
  };

  const removeEvent = async (eventId) => {
    try {
      // Get a reference to the document by ID
      const eventsRef = collection(firestoreDB, "Events");
      const eventDocRef = doc(eventsRef, eventId);

      // Delete the document
      await deleteDoc(eventDocRef);
      setState({
        ...state,
        events: events.filter((event) => event.id !== eventId),
      });
      setEvents(events.filter((event) => event.id !== eventId));
    } catch (error) {
      console.error("Error removing event: ", error);
    }
  };

  const priceDisplay = (event) => {
    return !hasEvent(state.user?.transactions, event) ? (
      <div className="fw-bold">{event.price.toFixed(2)} ש"ח</div>
    ) : (
      <div className="fw-bold">נרכש</div>
    );
    return !hasEvent(state.user?.transactions, event) ? (
      <div className="fw-bold">{event.price.toFixed(2)} ש"ח</div>
    ) : (
      <div className="fw-bold">נרכש</div>
    );
  };
  const eventAction = (event) => {
    return hasEvent(state.user?.transactions, event)
      ? // return hasEvent(state.user?.transactions, event)
        enterEvent(event)
      : goBuy(event);
  };

  const eventActionBtnText = (event) => {
    return hasEvent(state.user?.transactions, event) ? "להדרכה" : "הרשמה";
  };
  return (
    <div className="online-page">
      <div className="cover">
        <div className="cover-image">
          <img src={AboutCoverImg} />
          <div className="cover-fade" />
        </div>
      </div>

      <div className="d-flex flex-row justify-content-center align-items-center">
        <h3 className="text-center mt-3">הדרכות אונליין</h3>
      </div>
      {events?.filter((ev) => ev.active).length === 0 && (
        <div className="fs-3 text-center">בקרוב יפורסמו הדרכות חדשות</div>
      )}
      <Container className="events mt-3">
        {events
          .sort((a, b) => a.date - b.date)
          .map((event) =>
            state.user?.admin || event.active ? (
              <Row
                className=""
                key={`event-${event.id}`}
              >
                <Card className="event-card shadow">
                  <Card.Body className="event-body">
                    <div className="event-info">
                      <Card.Text className="title h5">{event.title}</Card.Text>
                      <div className="event-intro mb-3">
                        <div
                          dangerouslySetInnerHTML={{ __html: event.intro }}
                        />
                      </div>
                      <div className="event-schedule">
                        <span>
                          <Icon path={mdiCalendarMonth} size={1} /> {event.date}
                        </span>
                        <span>
                          <Icon path={mdiClockOutline} size={1} /> {event.time}
                        </span>
                        <span>
                          <Icon path={mdiTimerSand} size={1} /> {event.duration}{" "}
                          דקות
                        </span>
                        {state.user?.admin && !event.active && (
                          <span className="fw-bold text-danger">לא פעיל</span>
                        )}
                      </div>
                      {/* <Card.Text className='fw-bold d-flex flex-row-reverse ms-auto'> */}
                      {/* </Card.Text> */}
                      <Link to={`/online/${event.id}`}>
                        <Button className="info-btn shadow mt-2" variant="primary">
                          פרטים
                        </Button>
                      </Link>
                    </div>
                    <div className="event-promo">
                      {event.image_url && (
                        <img
                          src={event.image_url}
                          className="shadow-lg event-image"
                          width={"100px"}
                        />
                      )}
                      <div
                        onClick={() => eventAction(event)}
                        className="d-flex flex-column justify-content-center align-items-center mt-3"
                      >
                        <div className="price-display">{priceDisplay(event)}</div>
                        <div className="action-btn shadow">
                          <Button variant={eventActionBtnText(event) ==='הרשמה' ? "success" : "info"}>
                            {eventActionBtnText(event)}
                          </Button>
                        </div>
                      </div>
                    </div>
                  </Card.Body>
                </Card>
              </Row>
            ) : null
          )}
      </Container>
    </div>
  );
};

export default OnlinePage;
