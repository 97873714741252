import Container from "react-bootstrap/Container";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";

import "./NavBar.css";
import { Link, useNavigate } from "react-router-dom";
import { useGoogleLogin } from "@react-oauth/google";
import { getUser } from "../../assets/DataUtils";
import logo from "/home/omridev/new-dev/gal-tools/gal-tools/src/assets/images/logo_trans.png";
const NavBar = ({ state, setState }) => {
  const nvaigate = useNavigate();
  const closeMenu = () => {
    if (window.innerWidth > 768) {
      return null;
    }

    // Close the menu by toggling the navbar collapse state
    const navbarCollapse = document.getElementById("navbar-toggle-btn");
    navbarCollapse.click();
  };

  const login = useGoogleLogin({
    onSuccess: async (data) => {
      await getUser(data, state, setState);
      nvaigate("/");
    },
  });

  const logout = () => {
    localStorage.removeItem("pedagogy_user");
    setState({ ...state, user: null });
  };

  return (
    <Navbar id="navbar" expand="lg" className="bg-body-tertiary rtl">
      <Container className="container-fluid">
        <Navbar.Brand className="fw-bold">
          <Link to={"/"}><img src={'/logo_trans.png'} alt="logo" className="logo"/></Link>
        </Navbar.Brand>
        <Navbar.Toggle
          id="navbar-toggle-btn"
          aria-controls="basic-navbar-nav"
        />
        <Navbar.Collapse id="basic-navbar-nav ltr">
          <Nav className="me-5">
            <Nav.Item className={"nav-item"} onClick={closeMenu}>
              <Link to={"/"}>דף הבית</Link>
            </Nav.Item>
            {/* <Nav.Item className={"nav-item"} onClick={closeMenu}>
              <Link to={"/blog"}>בלוג</Link>
            </Nav.Item> */}
            <Nav.Item className={"nav-item"} onClick={closeMenu}>
              <Link to={"/online"}>הדרכות אונליין</Link>
            </Nav.Item>
            <Nav.Item className={"nav-item"} onClick={closeMenu}>
              <Link to={"/vod"}>הדרכות מוקלטות</Link>
            </Nav.Item>
            <Nav.Item className={"nav-item"} onClick={closeMenu}>
              <Link to={"/account/buys"} id="buys-nav-item">
                החשבון שלי
              </Link>
            </Nav.Item>
            {/* {state.user?.id && state.user?.admin && (
              <Nav.Item className={"nav-item"} onClick={closeMenu}>
                <Link to={"/admin"}>אדמין</Link>
              </Nav.Item>
            )} */}
            <Nav.Item className="login-btn">
              <span>
                {state.user?.name ? state.user.given_name + " | " : ""}
              </span>
              <span onClick={() => (state.user?.id ? logout() : login())}>
                {state.user?.id ? "התנתקות" : "התחברות"}
              </span>
            </Nav.Item>
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
};

export default NavBar;
