import moment from "moment";
import React, { useState } from "react";

const Buy = ({ transaction, index }) => {
  const [expend, setExpend] = useState(false);

  if (expend) {
    return (
      <div className="buy card" key={index}>
        <div className="details">
          {Object.entries(transaction).map(([key, value]) => (
            <div key={key}>
              {key}: {value}
            </div>
          ))}
        </div>
      </div>
    );
  } else {
    return (
      <div className="m-2">
        <div className="buy card">
          <div>
            תאריך רכישה: <span className="fw-bold">{transaction.DealDate}</span>
          </div>
          <div>
            מוצרים:{" "}
            <span className="fw-bold">{transaction.GroupDescription}</span>
          </div>
          <div>
            פריטים:{" "}
            <span className="fw-bold">{transaction.ProdTotalLines}</span>
          </div>
          <div>
            סכום: <span className="fw-bold">{transaction.suminfull} ש"ח</span>
          </div>
        </div>
      </div>
    );
  }
};

export default Buy;
