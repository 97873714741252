import React, { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import "./EventPage.css";
import Icon from "@mdi/react";
import { mdiCalendarMonth, mdiClockOutline, mdiTimerSand } from "@mdi/js";
import moment from "moment";
const EventPage = ({ state }) => {
  const { event_id } = useParams();
  const [event, setEvent] = useState({});
  useEffect(() => {
    let ev = state.events.find((e) => e.id == event_id);
    setEvent(ev);
  }, [state.events]);
  const eventDay = () => {
    const dateString = event.date
    if(!dateString) return ''
    // Split the date string into day, month, and year parts
    const [day, month, year] = dateString?.split('.');
    
    // Create a Date object using the parsed day, month, and year
    const date = new Date(`${year}-${month}-${day}`);
    
    // Define an array of Hebrew day names
    const hebrewDayNames = [
      'יום ראשון',
      'יום שני',
      'יום שלישי',
      'יום רביעי',
      'יום חמישי',
      'יום שישי',
      'יום שבת',
    ];
    
    // Get the day of the week (0 = Sunday, 1 = Monday, ..., 6 = Saturday)
    const dayOfWeek = date.getDay();
    
    // Get the corresponding Hebrew day name
    const hebrewDayOfWeek = hebrewDayNames[dayOfWeek];
        return hebrewDayOfWeek
  };
  return (
    <>
      <div className="cover full">
        <div className="cover-image">
          <img src={event.image_url} />
          <div className="cover-fade" />
        </div>
      </div>
      <div className="fs-4 text-center my-3 fw-bold title-page">
        {event?.title}
      </div>
      <div className="event card">
        
        <div dangerouslySetInnerHTML={{ __html: event?.html }} />
        <div className="d-flex flex-row justify-content-around mt-2">
          <span>
            <Icon path={mdiCalendarMonth} size={1} /> {event.date}
          </span>
          <span>
            <Icon path={mdiCalendarMonth} size={1} /> {eventDay()}
          </span>
          <span>
            <Icon path={mdiClockOutline} size={1} /> {event.time}
          </span>
          <span>
            <Icon path={mdiTimerSand} size={1} /> {event.duration} דקות
          </span>
        </div>
      </div>
      <div className="d-flex flex-row justify-content-center mt-5">
        <Link to={`/online/${event?.id}/register`}>
          <div className="btn btn-success">רכישת הדרכה</div>
        </Link>
      </div>
    </>
  );
};

export default EventPage;
