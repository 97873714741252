import { doc, getDoc, setDoc } from "firebase/firestore";
import { firestoreDB } from "../firestore";
import moment from "moment";
import { collection, query, where, getDocs } from "firebase/firestore";

export const getUser = async (data, state, setState) => {
    let access_token = data.access_token;
    const res = await fetch(
        `https://www.googleapis.com/oauth2/v1/userinfo?alt=json&access_token=${access_token}`
    )
    const googleData = await res.json()

    if (googleData) {
        const userDBData = await getUserData(googleData.id)
        let userData = await setUserData(userDBData || googleData)
        // let transactions = await getUserTransactions(userData)
        // userData["transactions"] = transactions;
        setState({ ...state, user: userData });
        localStorage.setItem("pedagogy_user", JSON.stringify(userData));
    }
};

export const getUserData = async (userId) => {
    const docRef = doc(firestoreDB, "Users", userId);
    const docSnap = await getDoc(docRef);
    if (docSnap.exists()) {
        const userData = docSnap.data();
        userData["transactions"] = await getUserTransactions(userData);
        return userData;
    } else {
        // Document doesn't exist
        return null;
    }
};

const setUserData = async (data) => {
    data["last_login"] = moment().format();
    await setDoc(doc(firestoreDB, "Users", data.id), data);
    return data;
};



export const getTransactions = async () => {
    const transactionsRef = collection(firestoreDB, "Transactions");

    const querySnapshot = await getDocs(transactionsRef);

    if (!querySnapshot.empty) {
        const transactions = [];
        querySnapshot.forEach((doc) => {
            transactions.push(doc.data());
        });
        return transactions
    } else {
        // No documents found
        return [];
    }
};

export const getUserTransactions = async (userData) => {
    const transactionsRef = collection(firestoreDB, "Transactions");

    const q = query(
        transactionsRef,
        where("UserEmail", "==", userData.email),
    );

    const querySnapshot = await getDocs(q);

    if (!querySnapshot.empty) {
        const transactions = [];
        querySnapshot.forEach((doc) => {
            transactions.push(doc.data());
        });
        return transactions;
    } else {
        // No matching documents found
        return [];
    }
};

export const getEvents = async () => {
    const eventsRef = collection(firestoreDB, "Events");
    const querySnapshot = await getDocs(eventsRef);
    if (!querySnapshot.empty) {
        const events = [];
        querySnapshot.forEach((doc) => {
            events.push(doc.data());
        });
        return events;
    } else {
        // No documents found
        return [];
    }
}

export const getVideos = async () => {
    const videoRef = collection(firestoreDB, "Videos");
    try {
        const querySnapshot = await getDocs(videoRef);
        if (!querySnapshot.empty) {
            const videos = [];
            querySnapshot.forEach((doc) => {
                videos.push(doc.data());
            });
            return videos;
        } else {
            // No documents found
            return [];
        }
    } catch (error) {
        alert('שגיאת שרת')
    }
}