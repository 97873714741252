import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import Unauthorized from "../../Unauthorized";
import { hasEvent, hasItem } from "../../../assets/Utils";
import "./VideoPage.css";
import VideoPageInfo from "./VideoPageInfo";
const VideoPage = ({ state, setState, info }) => {
  const { video_id } = useParams();
  const { event_id } = useParams();
  const [video, setVideo] = useState(state.videos.find((v) => v.id === video_id) || state.events.find((e) => e.id === event_id));

  useEffect(() => {
    if(!video) {
      setVideo(state.videos.find((v) => v.id === video_id) || state.events.find((e) => e.id === event_id));
    }
  })

  if (!info && !state.user?.id) {
    return <Unauthorized />;
  }
  const transactions = state.user?.transactions;

  if (info) {
    return <VideoPageInfo video={video}/>
  } else {
    if(!hasItem(transactions, video.id) && !hasEvent(transactions, video.id)) {
      return <div>טרם רכשת פריט זה</div>;
    }
  }
  return (
    <div className="video-page">
      <div className="fs-4 fw-bold mb-3">{video?.title}</div>
      <div id="video-frame">
        <video
          style={{ maxWidth: "100%" }}
          controls
          controlsList="nodownload"
          disablePictureInPicture
          onContextMenu={(e) => e.preventDefault()}
        >
          <source
            src={video?.video_url}
            type="video/mp4"
          />
        </video>{" "}
      </div>
    </div>
  );
};

export default VideoPage;
