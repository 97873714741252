import React from "react";
import "./Account.css";
import { useNavigate } from "react-router-dom";
import { addDoc, collection, doc, setDoc } from "firebase/firestore";
import { firestoreDB } from "../../firestore";
import Unauthorized from "../Unauthorized";
import Buys from "./Buys";
const Account = ({ state, setState }) => {
  const navigate = useNavigate();

  const createTranscation = async () => {
    if (state.user.transactions?.length > 0) {
      let trans = state.user.transactions[0];
      trans["UserEmail"] = state.user.email;
      trans["intTo"] = state.user.name;
      trans["CardOwnerName"] = state.user.name;
      trans["responsdescription"] = "פיקטיבי";
      await addDoc(collection(firestoreDB, "Transactions"), trans);
    }
  };
  if (state.user?.id) {
    return (
      <div className="account">
        <div className="sub-navbar">
          <div
            className="navbar-link-item"
            onClick={() => navigate("/account/buys")}
          >
            הרכישות שלי
          </div>
          {/* <div className="navbar-link-item" onClick={() => createTranscation()}>
            זייף רכישה אחרונה
          </div> */}
          {/* <Buys state={state} setState={setState} /> */}
        </div>
      </div>
    );
  } else {
    return <Unauthorized />;
  }
};

export default Account;
