import moment from "moment";
import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import EditorJsContainer from "../../../assets/react-editor-js";
import { doc, setDoc } from "firebase/firestore";
import { firestoreDB } from "../../../firestore";
import "./ManageEvent.css";
import { toast } from "react-hot-toast";

const ManageEvent = ({ state, setState }) => {
  const { eventId } = useParams();
  const [formData, setFormData] = useState({
    id: eventId || "",
    title: "",
    intro: "",
    date: moment().format("DD/MM/YYYY"),
    price: 0,
    time: "",
    duration: 0,
    details: "",
    image_url: "",
    html: "",
    published_datetime: moment().format("YYYY-MM-DD HH:mm"),
  });

  useEffect(() => {
    if (eventId && state.events) {
      let ev = state.events.find((e) => e.id === eventId);
      if (ev) {
        setFormData(ev);
      }
    }
  }, [eventId, state.events]);

  const handleFormChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  const handleSave = async () => {
    toast.promise(
      setDoc(doc(firestoreDB, "Events", formData.id), formData),
       {
         loading: 'שומר...',
         success: <b>נשמר בהצלחה!</b>,
         error: <b>יש באג.</b>,
       }
     );
    
  };

  var toolbarOptions = [
    [{ container: "#toolbar" }],
    ["bold", "italic", "underline", "strike"], // toggled buttons
    ["blockquote", "code-block"],

    [{ header: 1 }, { header: 2 }], // custom button values
    [{ list: "ordered" }, { list: "bullet" }],
    [{ script: "sub" }, { script: "super" }], // superscript/subscript
    [{ indent: "-1" }, { indent: "+1" }], // outdent/indent
    [{ direction: "rtl" }], // text direction

    [{ size: ["small", false, "large", "huge"] }], // custom dropdown
    [{ header: [1, 2, 3, 4, 5, 6, false] }],

    [{ color: [] }, { background: [] }], // dropdown with defaults from theme
    [{ font: [] }],
    [{ align: [] }],

    ["clean"], // remove formatting button
  ];
  const formats = [
    "font",
    "size",
    "bold",
    "italic",
    "underline",
    "strike",
    "color",
    "background",
    "script",
    "header",
    "blockquote",
    "code-block",
    "indent",
    "list",
    "direction",
    "align",
    "link",
    "image",
    "video",
    "formula",
  ];

  const handleImageUpload = (e) => {
    const file = e.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setFormData({
          ...formData,
          image_url: reader.result,
        });
      };
      reader.readAsDataURL(file);
    } else {
      setFormData({
        ...formData,
        image_url: "",
      });
    }
  };

  const handleClearImage = () => {
    setFormData({
      ...formData,
      image_url: "",
    });
  };

  const modules = {
    toolbar: toolbarOptions,
  };
  return (
    <div className="manage-event">
      <div className="container-fluid">
        <h5 className="text-center my-2">
          {eventId ? "עריכת אירוע" : "יצירת אירוע חדש"}
        </h5>
        <div className="text-center my-2">
          <button className="btn btn-primary" onClick={handleSave}>
            שמור
          </button>
        </div>

        <div className="d-flex flex-row">
          <div className="d-flex flex-column col-8">
            <div className="mb-3 d-flex flex-row">
              <label htmlFor="id" className="form-label">
                id
              </label>
              <input
                type="text"
                id="id"
                name="id"
                className="me-5 form-control"
                value={formData.id}
                onChange={handleFormChange}
                placeholder="כותרת"
              />
            </div>
            <div className="mb-3 d-flex flex-row">
              <label htmlFor="title" className="form-label">
                כותרת
              </label>
              <input
                type="text"
                id="title"
                name="title"
                className="form-control me-3"
                value={formData.title}
                onChange={handleFormChange}
                placeholder="כותרת"
              />
            </div>
          </div>
          <div className="d-flex flex-column col-4 justify-content-center align-items-center">
            <label htmlFor="image" className="form-label"></label>
            {formData.image_url && (
              <div className="d-flex flex-column">
                <img
                  src={formData.image_url}
                  alt="Selected Image"
                  className="shadow-lg event-image"
                  width={"100px"}
                />
                <button
                  className="btn btn-sm btn-outline-secondary my-1"
                  onClick={handleClearImage}
                >
                  מחיקת תמונה
                </button>
              </div>
            )}
            {!formData.image_url && (
              <div className="d-flex flex-column justify-content-center text-center me-3">
                בחירת תמונה
                <input
                  type="file"
                  id="image"
                  name="image"
                  className="form-control"
                  accept="image/*"
                  onChange={handleImageUpload}
                />
              </div>
            )}{" "}
          </div>
        </div>
        <div className="mb-3">
          <div className="d-flex flex-row justify-content-around align-items-baseline">
            {/* <div className=""> */}
            <label htmlFor="date" className="form-label">
              תאריך
            </label>
            <input
              type="date"
              id="date"
              name="date"
              className="form-control mx-3"
              value={moment(formData.date).format(
                "YYYY-MM-DD"
              )}
              onChange={handleFormChange}
              placeholder="תאריך"
            />
            {/* </div> */}
            {/* <div className=""> */}
            <label htmlFor="time" className="mx-3 form-label">
              שעה
            </label>
            <input
              type="time"
              id="time"
              name="time"
              className="form-control"
              value={formData.time}
              onChange={handleFormChange}
              placeholder="שעה"
            />
            {/* </div> */}
          </div>
          <div className="d-flex flex-row justify-content-around align-items-baseline">
            {/* <div className=""> */}
            <label htmlFor="duration" className="form-label">
              משך
            </label>
            <input
              type="number"
              id="duration"
              name="duration"
              className="form-control mx-4"
              value={formData.duration}
              onChange={handleFormChange}
              placeholder="משך האירוע"
            />
            {/* </div> */}
            {/* <div className=""> */}
            <label htmlFor="published_datetime" className="mx-3 form-label">
              זמן פרסום
            </label>
            <input
              type="datetime-local"
              id="published_datetime"
              name="published_datetime"
              className="form-control"
              value={moment(formData.published_datetime).format(
                "YYYY-MM-DD HH:mm"
              )}
              onChange={(value) => {
                value = moment(value).format("YYYY-MM-DD HH:mm");
                handleFormChange({ target: { name: "intro", value } });
              }}
              placeholder="זמן פרסום"
            />
            {/* </div> */}
          </div>
        </div>
        <div className="d-flex flex-row justify-content-around align-items-baseline">
          <label htmlFor="price" className="form-label">
            מחיר
          </label>
          <input
            type="number"
            id="price"
            name="price"
            className="form-control mx-3"
            value={formData.price}
            onChange={handleFormChange}
            placeholder="מחיר"
          />
        </div>
        <div className="mb-3">
          <label htmlFor="intro" className="form-label">
            הקדמה
          </label>
          <ReactQuill
            modules={modules}
            formats={formats}
            value={formData.intro}
            onChange={(value) =>
              handleFormChange({ target: { name: "intro", value } })
            }
            placeholder="הקדמה"
          />
        </div>
        <div className="mb-3">
          <label htmlFor="details" className="form-label">
            פרטים
          </label>
          <ReactQuill
            modules={modules}
            formats={formats}
            value={formData.details}
            onChange={(value) =>
              handleFormChange({ target: { name: "details", value } })
            }
            placeholder="פרטים"
          />
        </div>
        {/* Add other input fields for date, price, time, duration, details, image_url, published */}
        <div className="mb-3">
          <label htmlFor="html" className="form-label">
            תוכן HTML
          </label>
          <ReactQuill
            modules={modules}
            formats={formats}
            value={formData.html}
            onChange={(value) =>
              handleFormChange({ target: { name: "html", value } })
            }
            placeholder="תוכן HTML"
          />
        </div>
        <div>
          <label htmlFor="html" className="form-label">
            תוכן HTML - תצוגה מקדימה
          </label>
          <div dangerouslySetInnerHTML={{ __html: formData.html }} />
        </div>
      </div>
    </div>
  );
};

export default ManageEvent;
