import React from 'react'
import './About.css'
const About = () => {
  return (
    <div className='about-page'>
      <div className='cover'>
        <div className='cover-image'>
          <img src={'AboutCoverImg'} />
          <div className='cover-fade'/>
        </div>
      </div>
      <div className='about-page-body m-2'>
        <h3 className='text-center my-4'>רק על עצמי לספר ידעתי...</h3>
      </div>
    </div>
  )
}

export default About