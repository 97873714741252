import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";

const EventForm = ({ state }) => {
  const event_id = useParams().event_id;
  const [bought, setBought] = useState(false);
  const [event, setEvent] = useState(
    state.events.find((e) => e.id == event_id) || null
  );
  useEffect(() => {
    if (document.getElementById("iframe")) {
      if (!bought) {
        checkIframe();
      }
    }
    // Check for URL changes periodically (e.g., every second)
  }, []);

  const checkIframe = async () => {
    const intervalId = setInterval(function () {
      const currentURL = document.getElementById("iframe")?.contentWindow
        .location.href;
      if (currentURL?.includes("thank_you") || currentURL?.includes("SuccessAndFailDealPage/Success")) {
        setBought(true);
        clearInterval(intervalId); // Clear the interval
      }
    }, 1000);
  };
  const handleBuy = async () => {
    setTimeout(() => {
      window.location = "/online";
    }, 1000 * 5);
  };
  useEffect(() => {
    if (bought) {
      handleBuy();
    }
  }, [bought]);

  return (
    <div className="payment-page">
      <iframe
        id="iframe"
        // src={"https://secure.cardcom.solutions/e/Sh2VDdptNEaroXPbNkb1A"}
        src={event?.buy_url ? event.buy_url : "https://secure.cardcom.solutions/e/Sh2VDdptNEaroXPbNkb1A"}
        // src="http://localhost:3001/thank_you/online"
        width="100%"
        title="Cardcom Payment Form"
      ></iframe>
    </div>
  );
};

export default EventForm;
