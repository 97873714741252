import React from 'react'
import { useNavigate } from 'react-router-dom'

const AdminPanel = () => {
  const navigate = useNavigate()
  return (
    <div className='m-5'>
      <div className='btn btn-success' onClick={() => navigate('/admin/new-event')}>אירוע חדש</div>
    </div>
  )
}

export default AdminPanel