import React from "react";
import { Link, useParams } from "react-router-dom";
import Unauthorized from "../../Unauthorized";
import { hasItem } from "../../../assets/Utils";
import "./VideoPage.css";
const VideoPageInfo = ({ video }) => {
  return (
    <div className="video-page-info">
      <div className="cover">
        <div className="cover-image">
          <img src={video?.image_url} />
          <div className="cover-fade" />
        </div>
      </div>
      <div className="text-center fw-bold title-page">{video?.title}</div>
      {video?.html && (
        <div className="video card" dangerouslySetInnerHTML={{ __html: video.html }} />
      )}
      <div className="d-flex flex-row justify-content-center mt-5">
        <Link to={`/vod/${video?.id}/buy`}>
          <div className="btn btn-success">רכישת הדרכה</div>
        </Link>
      </div>
    </div>
  );
};

export default VideoPageInfo;
