import React, { useEffect } from "react";
import Unauthorized from "../Unauthorized";
import "./Account.css";
import Buy from "./Buy";
import { useNavigate } from "react-router-dom";
import { getUserTransactions } from "../../assets/DataUtils.js";
const Buys = ({ state, setState }) => {
  const navigate = useNavigate();
  const setup = async () => {
    let transactions = await getUserTransactions(state.user);
    if(transactions && state.user?.id) {
      setState({ ...state, user: { ...state.user, transactions } });
    }
  };
  useEffect(() => {
    if (!state.user?.id) {
      return navigate("/account");
    } else {
      if (!state.user?.transactions) {
        setup();
      }
    }
  }, [state.user?.id]);

  return (
    <div className="buys">
      {state.user?.transactions
        ? state.user?.transactions
            .sort((a, b) => {
              return new Date(b.DealDate) - new Date(a.DealDate);
            })
            .map((item, index) => <Buy transaction={item} index={index} />)
        : "אין רכישות"}
    </div>
  );
};

export default Buys;

// invType: 1
// Custom19: 2
// InvPhone:
// GroupDescription: מלמדים לומדים - הדרכת GPT
// CardOwnerName: עומרי עוזר
// InvAddress:
// cointype: 1
// ConstPayment: 0
// ProdQuantity: 1
// CardOwnerPhone: 0508958188
// Lest4Numbers: 9681
// invoicenumber: 11
// responsdescription: פיקטיבי
// suminfull: 45
// InvAddress2: מיקוד/ת.ד
// ProdPrice: 45
// intTo: עומרי עוזר
// UID: 23080821131829642501917
// invNumber: 11
// terminalnumber: 145063
// DealTime: 21:12
// suminagorot: 4500
// InvMobile: 0508958188
// UserIP: 147.235.206.151
// ApprovelNumber: 2373388
// CardOwnerID: 032272221
// AffiliateID: 1215375
// ProdTotalLines: 1
// UserEmail: ozeromri@gmail.com
// CardYear: 2025
// MutagName: מאסטרקארד
// ProdName: הדרכת GPT
// xparameter: 0
// intCity:
// invCompanyID: 032272221
