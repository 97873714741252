import moment from "moment";

export const hasItem = (trans, item_id) => {
    const fourteenDaysAgo = new Date(Date.now() - 14 * 24 * 60 * 60 * 1000);
    return trans?.filter((tran) => new Date(tran.DealDate) >= fourteenDaysAgo)?.some((transaction) => {
        return Object.keys(transaction).some((key) => key.includes("ProductID") && transaction[key] === item_id);
    });

}

export const hasEvent = (trans, event) => {
    if(!trans) return false 
    return trans?.some((transaction) => {
        return Object.keys(transaction).some((key) => key.includes("ProductID") && transaction[key] === event.id);
    })
}

export const findItem = (trans, item_id) => {
    if(!trans) {
        return false
    }
    return trans?.find((transaction) => {
        return Object.keys(transaction).some((key) => key.includes("ProductID") && transaction[key] === item_id);
    });
}