// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";
import { getAnalytics } from "firebase/analytics";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyB2STUIgca3YQ9UBiKG4cVo4G8YOh1gDfc",
  authDomain: "pedagogygal-api.firebaseapp.com",
  projectId: "pedagogygal-api",
  storageBucket: "pedagogygal-api.appspot.com",
  messagingSenderId: "485751300289",
  appId: "1:485751300289:web:e797f4a878c9423cc63440",
  measurementId: "G-VXKFRG25FW"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);

export const firestoreDB = getFirestore(app)