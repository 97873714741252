import React, { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import moment from "moment";
import { hasEvent } from "../../../assets/Utils";
import Unauthorized from "../../Unauthorized";

const EventCountDownPage = ({ state }) => {
  const { event_id } = useParams();
  const [event, setEvent] = useState(
    state?.events?.find((event) => event.id == event_id)
  );

  const [countdown, setCountdown] = useState("");

  useEffect(() => {
    if (!event) {
      setEvent(state?.events?.find((event) => event.id == event_id));
    }
    const interval = setInterval(() => {
      const now = new Date();
      const eventDateTime = moment(
        `${event.date} ${event.time}`,
        "DD.MM.YYYY HH:mm"
      );
      const diff = eventDateTime - now;
      const duration = moment.duration(diff);
      const days = Math.floor(duration.asDays());
      const hours = duration.hours();
      const minutes = duration.minutes();
      const seconds = duration.seconds();

      let countdownText = "";

      if (days > 0) {
        countdownText += `${days} ימים, `;
      }
      if (hours > 0) {
        countdownText += `${hours} שעות, `;
      }
      if (minutes > 0) {
        countdownText += `${minutes} דקות ו- `;
      }

      countdownText += `${seconds} שניות`;

      setCountdown(countdownText);

      if (diff <= 0) {
        clearInterval(interval);
        setCountdown("");
      }
    }, 1000);

    return () => {
      clearInterval(interval);
    };
  }, [event?.date]);

  if (!state.user?.id) {
    return <Unauthorized />;
  }

  if (!hasEvent(state.user.transactions, event)) {
    return <div>טרם רכשת פריט זה</div>;
  }

  if (!event) {
    return <div>לא נמצא וידיאו להצגה</div>;
  }

  const eventDateTime = moment(
    `${event.date} ${event.time}`,
    "DD.MM.YYYY HH:mm"
  );
  const now = moment();

  if (now.isAfter(eventDateTime.add(event.duration, "minutes"))) {
    if (event.video_url) {
      return (
        <div className="container">
          <div className="card mt-5 p-5 text-center">
            <div>ההקלטה זמינה:</div>
            <Link to={"/vod/events/" + event.id}>
              <div className="btn btn-success">צפה בהקלטה</div>
            </Link>
          </div>
        </div>
      );
    } else {
      return (
        <div className="container">
          <div className="card mt-5 p-5 text-center">
            <div>ההדרכה תעלה עד 48 שעות ממועד ההדרכה</div>
          </div>
        </div>
      );
    }
  }

  if (
    eventDateTime.isBetween(
      now.clone().subtract(5, "minutes"),
      now.clone().add(event.duration, "minutes")
    )
  ) {
    return (
      <div className="container">
        <div className="card mt-5 p-5 text-center">
          <div className="btn btn-success">צפה באירוע</div>
        </div>
      </div>
    );
  }

  if (countdown && countdown !== "") {
    return (
      <div className="container">
        <div className="card mt-5 p-5 text-center">
          ההדרכה תתחיל בעוד
          <div>{countdown}</div>
          בתאריך
          <div>
            {moment(event.date, "DD.MM.YYYY").format("DD.MM.YYYY")} {event.time}
          </div>
        </div>
      </div>
    );
  }

  return null;
};

export default EventCountDownPage;
