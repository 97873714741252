import React from "react";

const Unauthorized = () => {
  return (
    <div className="m-5">
      <div className="card p-5">
        <div className="text-center fw-bold">
          הגישה לדף זה הינה למשתמשים רשומים בלבד
        </div>
      </div>
    </div>
  );
};

export default Unauthorized;
