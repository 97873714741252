import React, { useState } from 'react'
import './Home.css';
import AboutCoverImg from "../../assets/images/home_cover.png";

const Home = () => {
    return (
        <div className='home'>
            <div className="cover">
                <div className="cover-image">
                    <img src={AboutCoverImg} />
                    <div className="cover-fade" />
                </div>
            </div>
            <div className='d-flex flex-column justify-content-center align-items-center'>
                <div className='text-center my-3 fs-4 fw-bold'>גל עוזר - חינוך מתקדם</div>
                <div className='home-text card'>
                    שלום, אני גל עוזר, מורה לשפה עם תואר שני בחינוך.
                    <br /><br />

                    אני מאמינה שבינה מלאכותית (AI) היא כלי רב עוצמה שיכול לשפר את ההוראה של מורים.
                    <br /><br />

                    באתר שלי, אני חולקת את הידע והניסיון שלי בשימוש ב-AI בחינוך.
                    <br /><br />

                    אני כותבת על נושאים כמו:
                    <br /><br />

                    כיצד להשתמש ב-AI כדי ליצור חומרי לימוד מותאמים אישית יותר לתלמידים.
                    <br />
                    כיצד להנגיש את החינוך לתלמידים עם מוגבלויות.
                    <br />
                    כיצד להשתמש ב-AI כדי לזהות קשיים בלמידה.
                    <br />
                    כיצד להשתמש ב-AI כדי ליצור חומרי לימוד מרתקים ומעורבים יותר.
                    <br />
                    אני גם מציעה מגוון שירותים למורים המעוניינים ללמוד עוד על שימוש ב-AI בחינוך, כולל:
                    <br /><br />

                    הדרכות מקוונות או מוקלטות על נושאים שונים בשימוש ב-AI בחינוך.
                    <br />
                    הדרכות במליאה בבתי ספר וגנים.
                    <br />
                    סיוע בצוותי חשיבה בית ספריים בנושא שימוש ב-AI בחינוך.
                    <br />
                    ייעוץ אישי למורים המעוניינים לקבל סיוע פרטני בשימוש ב-AI בחינוך.
                    <br />
                    אני מאמינה שכל מורה יכול להשתמש ב-AI כדי לשפר את ההוראה שלו.
                    <br /><br />

                    באתר שלי, תמצאו את המידע והכלים שאתם צריכים כדי להתחיל.
                    <br /><br />

                    בואו ללמוד איתי כיצד להשתמש ב-AI כדי ליצור חינוך טוב יותר עבור כל התלמידים.
                </div>
            </div>
        </div>
    )
};

export default Home